<template>
    <svg
        class="luminfire-logo -ml-2"
        enable-background="new 0 0 612 187.68"
        viewBox="0 0 612 187.68"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            class="fill-brand-primary"
            d="m59.04 76.77-29.63 13.91 26.6 12.7 16.94 47.76 15.71-48.37 27.22-12.7-33.26-14.51-11.49-29.02z"
        />
        <path
            class="fill-brand-primary"
            d="m121.84 122.98c-2.47 1.54-4.88 3.24-7.12 5.11-6.09 5.45-11.61 12.08-12.72 20.93-3.31-11.06-11.06-21-22.1-26.53 6.63-2.22 12.15-6.64 15.87-12.43 2.09-2.5 4.02-5.25 5.12-8.58 1.11 2.22 2.36 4.42 3.73 6.56 4.01 5.6 8.43 11.13 15.1 14.22 1.3.47 2.12.72 2.12.72"
        />
        <g class="fill-brand-dark-gray dark:fill-brand-light-gray">
            <path d="m151.94 125.32h31.89v12.25h-46.73v-79.78h14.84z" />
            <path
                d="m201.19 80.96v35.97c0 3.46.8 6.13 2.4 8.03 1.6 1.89 4 2.84 7.2 2.84 2.35 0 4.56-.52 6.62-1.57s4.01-2.49 5.85-4.33v-40.94h13.63v56.61h-8.33c-1.8 0-2.94-.83-3.42-2.48l-.94-4.52c-1.18 1.18-2.39 2.25-3.64 3.23-1.25.97-2.59 1.81-4.03 2.51s-2.96 1.23-4.58 1.6-3.38.55-5.3.55c-3.09 0-5.82-.52-8.19-1.57s-4.37-2.53-5.99-4.44-2.84-4.18-3.67-6.81-1.24-5.53-1.24-8.69v-35.99z"
            />
            <path
                d="m243.91 137.57v-56.61h8.33c1.77 0 2.92.83 3.48 2.48l.88 4.19c.99-1.1 2.03-2.12 3.12-3.04 1.08-.92 2.24-1.71 3.48-2.37 1.23-.66 2.56-1.19 3.97-1.57 1.42-.39 2.97-.58 4.66-.58 3.57 0 6.5.97 8.8 2.9s4.02 4.5 5.16 7.7c.88-1.88 1.99-3.48 3.31-4.83 1.32-1.34 2.78-2.44 4.36-3.28s3.26-1.47 5.05-1.88c1.78-.4 3.58-.61 5.38-.61 3.12 0 5.9.48 8.33 1.43 2.43.96 4.47 2.35 6.12 4.19 1.66 1.84 2.91 4.08 3.78 6.73.86 2.65 1.3 5.68 1.3 9.1v36.03h-13.63v-36.03c0-3.6-.79-6.32-2.37-8.14s-3.9-2.73-6.95-2.73c-1.4 0-2.69.24-3.89.72-1.19.48-2.24 1.17-3.15 2.07-.9.9-1.61 2.03-2.12 3.39s-.77 2.92-.77 4.69v36.03h-13.68v-36.03c0-3.79-.76-6.55-2.29-8.28s-3.76-2.59-6.7-2.59c-1.99 0-3.83.49-5.54 1.46s-3.3 2.31-4.77 4v41.43h-13.65z"
            />
            <path
                d="m344.58 64.45c0 1-.2 1.94-.61 2.81-.41.88-.95 1.64-1.62 2.3s-1.46 1.18-2.37 1.57-1.88.59-2.91.59c-1 0-1.95-.19-2.84-.59-.89-.39-1.66-.92-2.32-1.57-.66-.66-1.18-1.42-1.57-2.3s-.59-1.81-.59-2.81c0-1.03.2-2 .59-2.91s.91-1.69 1.57-2.35 1.43-1.18 2.32-1.57 1.84-.59 2.84-.59c1.03 0 2 .2 2.91.59s1.69.91 2.37 1.57c.67.66 1.21 1.44 1.62 2.35.4.91.61 1.88.61 2.91m-.57 73.12h-13.68v-56.61h13.68z"
            />
            <path
                d="m351.48 137.57v-56.61h8.33c1.77 0 2.92.83 3.48 2.48l.94 4.47c1.14-1.18 2.34-2.24 3.61-3.2s2.61-1.78 4.03-2.48 2.93-1.23 4.55-1.6 3.38-.55 5.3-.55c3.09 0 5.83.52 8.22 1.57s4.39 2.52 5.99 4.41 2.81 4.16 3.64 6.79 1.24 5.53 1.24 8.69v36.03h-13.63v-36.03c0-3.46-.79-6.13-2.37-8.03-1.58-1.89-3.99-2.84-7.23-2.84-2.36 0-4.56.53-6.62 1.6s-4.01 2.52-5.85 4.36v40.94z"
            />
            <path d="m463.18 57.79v11.8h-35.42v23.62h34.5v11.86h-34.5v32.5h-14.9v-79.78z" />
            <path
                d="m484.18 64.45c0 1-.2 1.94-.61 2.81-.41.88-.95 1.64-1.62 2.3s-1.46 1.18-2.37 1.57-1.88.59-2.91.59c-1 0-1.95-.19-2.84-.59-.89-.39-1.67-.92-2.32-1.57-.66-.66-1.18-1.42-1.57-2.3s-.59-1.81-.59-2.81c0-1.03.19-2 .59-2.91.39-.91.91-1.69 1.57-2.35s1.43-1.18 2.32-1.57 1.84-.59 2.84-.59c1.03 0 2 .2 2.91.59s1.7.91 2.37 1.57 1.21 1.44 1.62 2.35.61 1.88.61 2.91m-.57 73.12h-13.68v-56.61h13.68z"
            />
            <path
                d="m492 137.57v-56.61h8c1.4 0 2.37.25 2.92.74.55.5.92 1.39 1.1 2.68l.77 6.4c1.8-3.38 3.92-6.04 6.34-7.97 2.43-1.93 5.3-2.9 8.61-2.9 2.61 0 4.71.57 6.29 1.71l-.88 10.21c-.18.66-.45 1.13-.8 1.41s-.82.41-1.41.41c-.55 0-1.37-.09-2.46-.28-1.08-.18-2.14-.28-3.17-.28-1.51 0-2.85.22-4.03.66s-2.24 1.08-3.17 1.9c-.94.83-1.77 1.83-2.48 3.01-.72 1.18-1.39 2.52-2.01 4.03v34.87h-13.62z"
            />
            <path
                d="m551.99 80.08c3.57 0 6.86.57 9.88 1.71s5.61 2.81 7.78 4.99c2.17 2.19 3.87 4.87 5.1 8.06 1.23 3.18 1.85 6.81 1.85 10.9 0 2.06-.22 3.45-.66 4.16-.44.72-1.29 1.08-2.54 1.08h-35.03c.22 2.91.74 5.42 1.54 7.53.81 2.12 1.88 3.86 3.2 5.24 1.33 1.38 2.9 2.41 4.72 3.09s3.84 1.02 6.04 1.02c2.21 0 4.11-.26 5.71-.77s3-1.08 4.19-1.71c1.2-.62 2.24-1.2 3.15-1.71.9-.52 1.77-.77 2.62-.77 1.14 0 1.99.42 2.54 1.27l3.92 4.97c-1.51 1.76-3.2 3.25-5.08 4.44-1.88 1.2-3.83 2.15-5.88 2.87-2.04.72-4.12 1.22-6.23 1.52-2.12.29-4.17.44-6.15.44-3.94 0-7.59-.65-10.98-1.96-3.38-1.3-6.33-3.24-8.83-5.79-2.5-2.56-4.47-5.72-5.9-9.49s-2.15-8.14-2.15-13.1c0-3.86.62-7.49 1.88-10.9 1.25-3.4 3.04-6.36 5.38-8.88 2.33-2.52 5.19-4.51 8.55-5.99 3.35-1.49 7.15-2.22 11.38-2.22m.27 9.76c-3.97 0-7.08 1.12-9.32 3.36s-3.68 5.43-4.3 9.55h25.71c0-1.77-.25-3.43-.74-4.99-.5-1.56-1.24-2.93-2.24-4.11-.99-1.18-2.25-2.1-3.78-2.79-1.53-.68-3.3-1.02-5.33-1.02"
            />
        </g>
    </svg>
</template>
